import React, { useState, useEffect, useCallback } from 'react';
import { createRoot } from 'react-dom/client';
import { Wizard, useWizard } from 'react-use-wizard';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import { doc, setDoc, addDoc, getFirestore, collection, query, firebase, where, orderBy, onSnapshot, getDocs } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { initializeApp } from 'firebase/app';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './i18n';
import TagManager from 'react-gtm-module'
import { useTranslation } from 'react-i18next';
import {
  FirebaseAppProvider,
  FirestoreProvider,
  useFirestoreDocData,
  useFirestoreCollectionData,
  useFirestore,
  useFirebaseApp,
} from 'reactfire';

import './style.css';
const firebaseConfig = {
  apiKey: 'AIzaSyDF9i9XsJNIEzf9RU3D_buVGdIYCz2mqvk',
  authDomain: 'repair2care-9a9b4.firebaseapp.com',
  projectId: 'repair2care-9a9b4',
  storageBucket: 'repair2care-9a9b4.appspot.com',
  messagingSenderId: '224954629809',
  appId: '1:224954629809:web:94bcfc6136b671fd586c90',
  measurementId: 'G-6P3B6C9LFZ',
};
const storage = getStorage(initializeApp(firebaseConfig));
var pathArray = window.location.pathname.split('/');
var country = pathArray[1].replace('%20', ' ');
var service = pathArray[2];
console.log(country.replace('%20', ' '));
var cartFilled = {}
var dataToBeUploaded = {
  provider: '',
  providerName: '',
  providerId: '',
  service: [],
  discount: '',
  total: '',
  serviceId: '',
  dateSlot: '',
  timeSlot: '',
  image1: '',
  image2: '',
  image3: null,
  image4: null,
  phone: '',
  carmanu: '',
  regnumber: '',
  clientEmail: '',
  clientId: 'bUzuJMVeGLdTTIIp3LCqf8Aifwj2',
  clientNameFirst: '',
  clientNameLast: '',
  providerCountry: country,
  company: '',
  organizationsNumber: '',
  state: 'created'
};
let nextId = 0;






function App() {

  if (country == "usa") {
    return (<div>
      <div className='space'></div>
      <h2>Country not yet supported</h2>
      <p>Please select a country from below</p>

      <a className='card-provider' href="/danmark">
        <div className='provider-link'>
          <div className='flex-provider'>
            <div className='space'></div>
            <span className='title'>Danmark</span>
            <span>da_DK</span>
            <div className='space'></div>

          </div>

        </div>
      </a>
      <a className='card-provider' href="/saudi arabia">
        <div className='provider-link'>
          <div className='flex-provider'>
            <div className='space'></div>
            <span className='title'>Saudi Arabia</span>
            <span>sa_AR</span>
            <div className='space'></div>

          </div>

        </div>
      </a>

      <a className='card-provider' href="/sweden">
        <div className='provider-link'>
          <div className='flex-provider'>
            <div className='space'></div>
            <span className='title'>Sweden</span>
            <span>sv_SE</span>
            <div className='space'></div>

          </div>

        </div>
      </a>

      <a className='card-provider' href="/germany">
        <div className='provider-link'>
          <div className='flex-provider'>
            <div className='space'></div>
            <span className='title'>Germany</span>
            <span>de_DE</span>
            <div className='space'></div>

          </div>

        </div>
      </a>


      <a className='card-provider' href="/south africa">
        <div className='provider-link'>
          <div className='flex-provider'>
            <div className='space'></div>
            <span className='title'>South Africa</span>
            <span>en_ZA</span>
            <div className='space'></div>

          </div>

        </div>
      </a>
    </div>)
  }

  const { t, i18n } = useTranslation();
  const step1Name = t('Services');
  const step2Name = t('Provider');
  const step3Name = t('Date');
  const step4Name = t('Upload');
  const step5Name = t("User details")
  const step6Name = t("Confirmation")
  const firestoreInstance = getFirestore(useFirebaseApp());
  document.body.dir = i18n.dir();
  const addData = async (dataToBeUploaded) => {
    const docRef = await addDoc(collection(useFirestore(), "bookings"), dataToBeUploaded)
    console.log("Document written with ID: ", docRef.id);
    return docRef.id



  }
  const [total, setTotal] = useState("")
















  const Panel = React.memo(function Panel({ title, children }) {
    const [isActive, setIsActive] = useState(false);
    const handleToggle = () => {
      setIsActive((current) => !current);
    };
    return (
      <div>
        <div className="accordion" aria-expanded={isActive} onClick={() => { handleToggle() }}>

          <h2>{title == 'undefined' ? "Uncategorised" : title}</h2>
          <i className='toggle fas fa-angle-right' aria-expanded={isActive} alt='Toggle Accordion'></i>

        </div>
        <div className="accordion-content" aria-expanded={!isActive}>
          {children}
        </div>

      </div>
    );
  });

  const tagManagerArgs = {
    gtmId: 'GTM-W9D45X4',
    dataLayerName: 'dataLayer'
  }



  TagManager.initialize(tagManagerArgs)


  const Step1 = () => {
    const { handleStep, previousStep, nextStep, goToStep } = useWizard();
    const [cart, setCart] = useState(dataToBeUploaded.service)
    const [showStore, setShowStore] = useState(true);
    const [discount, setDiscount] = useState(0);
    const firestore = getFirestore();
    const [services, setServices] = useState('')
    const [data, setData] = useState({ services: [], loading: true, error: null });
    const [formatter, setFormatter] = useState(null);
    useEffect(() => {
      let isMounted = true;

      const fetchServices = async (country) => {
        const firestore = getFirestore();
        const servicesCollection = collection(firestore, `/providers/${country}/services/`);
        const servicesQuery = query(servicesCollection, where('active', "==", true), orderBy('price', 'desc'));

        try {
          const querySnapshot = await getDocs(servicesQuery);
          const services = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));

          if (services.length > 0 && isMounted) {
            // Assuming currency is the same for all services, otherwise you'll need a more dynamic approach
            const currency = services[0].currency;

            var format = "";
            if (country == "usa") {
              format = "en-US"
            } else if (country == "denmark") {
              format = "da-DK"
            } else if (country == "sweden") {
              format = "sv-SE"
            } else if (country == "germany") {
              format = "de-DE"
            } else if (country == "south africa") {
              format = "en-ZA"
            } else if (country == "saudi arabia") {
              format = "sa-SA"
            } else {
              format = "da-DK"
            }

            const formatter = new Intl.NumberFormat(format, {
              style: 'currency',
              currency: currency,
            });
            const groups = _.groupBy(services, 'categories');
            console.log(services)
            
            const found = services.find(element => element.partno == service);
      
            if (found != undefined && cart.length == 0) {
              addToArray({ "name": found?.name, "partno": found?.partno, "price": found?.price, "id": nextId++ })

            }


            setFormatter(() => formatter); // Set formatter based on the first service's currency
            setData({ services, loading: false, error: null, groups }); // Update state with fetched services
          } else if (isMounted) {
            console.log('No services found');
            setData({ services: [], loading: false, error: 'No services found' });
          }
        } catch (error) {
          console.error("Error fetching services: ", error);
          if (isMounted) {
            setData({ services: [], loading: false, error: error.message });
          }
        }
      };

      fetchServices(country);

      return () => {
        isMounted = false;
      };
    }, [country]);


    //goToStep(3)


    // Simplified addToArray function
    const addToArray = useCallback((x) => {
      setCart((currentCart) => {
        if (currentCart.length > 100) {
          console.log("too many items");
          return currentCart;
        } else {
          toast.success(`${x.name} has been added`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'foo-bar',
          });
          return [...currentCart, x];
        }
      });
    }, []);
    function setService() {
      if (cart.length > 0) {
        dataToBeUploaded.service = cart;
        nextStep();
      }

    }
    const sum = cart.reduce((accumulator, object) => {
      var a = Number(accumulator);
      var o = Number(object.price);

      return a + o;
    }, 0);


    const removeFromArray = useCallback((x) => {
      setCart((current) => current.filter((item) => item.id !== x));
    }, []);

    const sort = cart.sort()
    const groups2 = _.groupBy(sort, 'partno');
    console.log(groups2)


    const useGetDiscount = (shouldFetch, coupon) => {
      const [data, setData] = useState(null);
      const [status, setStatus] = useState('idle');

      useEffect(() => {
        if (!shouldFetch) return;

        setStatus('loading');
        const discountLocation = collection(firestore, `/providers/${country}/voucher/`);
        const discountQuery = query(discountLocation, where('code', '==', coupon));

        const unsubscribe = onSnapshot(discountQuery, (snapshot) => {
          setData(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
          setStatus('success');
        });

        return () => unsubscribe();
      }, [shouldFetch, coupon, firestore]);

      return { status, data };
    };

    function MyComponent() {
      const [coupon, setCoupon] = useState('');
      const [triggerFetch, setTriggerFetch] = useState(false);

      const handleChange = (event) => {
        setCoupon(event.target.value);
      };

      const handleFetchData = () => {
        setTriggerFetch(prev => !prev);
      };

      const { status, data } = useGetDiscount(triggerFetch, coupon);

      useEffect(() => {
        if (status === 'success') {
          if (data && data.length > 0) {
            console.log("found coupon");
            console.log(data[0].amount);

            setDiscount(data[0].amount);
            dataToBeUploaded.discount = data[0].amount;

          } else {
            console.log("no coupon");
          }
        }
      }, [status, data]);

      return (
        <div style={{ flexDirection: 'row', flex: 1, display: 'flex', gap: 20, alignItems: 'center' }}>
          <input
            style={{ width: '75%' }}
            type="text"
            value={coupon}
            placeholder={t("enter-coupon")}
            onChange={handleChange}
          />
          <a className='cart-button'
            style={{ width: '25%', textAlign: 'center' }}

            onClick={handleFetchData}>{t("apply-coupon")}</a>
        </div>
      );
    }
    const GetCart = React.memo(function GetCart() {




      return (
     <div className="r2c-col2" >
          {data.loading && <p>Loading...</p>}
          {!data.loading && data.error && <p>Error loading services: {data.error}</p>}
          {!data.loading && data.services.length > 0 && formatter && (
         

              <div className='r2c-col-format'>
                <h2 className='cart-title'>{t('Services')}</h2>
                <table className='table'>
                <tbody>

                  {Object.entries(groups2).map(([key, value]) => (
                    <tr className='cart' key={value[0].id}>

                      <td className="cart-item">{value[0].name}</td>
                      <td className="cart-item">{value.length} {t('pcs')}</td>
                      <td className="cart-item">{formatter.format(value[0].price)}</td>
                      <td className="link" onClick={() => removeFromArray(value[0].id)} ><i className="trasn-icon fas fa-trash"></i></td>


                    </tr>
                  ))}
                  </tbody>
                </table>
                <div className='checkout-group'>
                  <div>
                    <p className='total-price'>{t('discount')} {formatter.format(discount)}</p>
                    <p className='total-price'>{t('Total Price')} {dataToBeUploaded.total = formatter.format(Math.max(0, sum - discount))} </p>

                  </div>

                  <a className='cart-button' onClick={() => setService()}>{t('Next')}</a>
                </div>
           
              <div className='r2c-col-format' style={{ marginTop: 10 }}>
                <div>

                  <MyComponent />

                </div>


              </div>

            </div>
          )}
          {!data.loading && data.services.length === 0 && !data.error && <p>No services found.</p>}
        </div>



      );

    });




    function ProductRows({ value }) {
      return (
        <div>

          {data.loading && <p>Loading...</p>}
          {!data.loading && data.error && <p>Error loading services: {data.error}</p>}
          {!data.loading && data.services.length > 0 && formatter && (
            <div>
              <div>
                {
                  value.map((services) => (
                    <div className="card" key={services.id}>
                      <img className='img' src={services.img} />
                      <div className='card-body'>

                        <div className='card-head' >
                          <div className='subtitle'>
                            <h2 className='cart-title'>
                              {services.name}
                            </h2>
                            <span className='subtitle'>{services?.subtitle}</span>
                          </div>

                          <div className='button-group'>
                            <span className='price'>{formatter.format(services.price)}</span>
                            <a className='add-to-cart' onClick={() => addToArray({ "name": services.name, "partno": services.partno, "price": services.price, "id": nextId++ })}  >

                              <i className="fas fa-cart-shopping"></i>
                            </a>
                          </div>
                        </div>


                        <p className='description' dangerouslySetInnerHTML={{ __html: services.description }}></p>




                      </div>


                    </div>

                  ))
                }
              </div>
            </div>
          )}

        </div>
      );

    }







    // Attach an optional handler
    handleStep(() => { });



    return (
      <>
        <div>
          <div className="steps">
            <a data-step-name={step1Name} data-active="y"></a>
            <a data-step-name={step2Name} data-active=""></a>
            <a data-step-name={step3Name} data-active=""></a>
            <a data-step-name={step4Name} data-active=""></a>
            <a data-step-name={step5Name} data-active=""></a>
            <a data-step-name={step6Name} data-active=""></a>

          </div>
          {data.loading && <p>Loading...</p>}
          {!data.loading && data.error && <p>Error loading services: {data.error}</p>}
          {!data.loading && data.services.length > 0 && formatter && (
            <div>


              <div className="responsive">

                <div className='r2c-col1'>

                  {
                    Object.entries(data.groups).map(([key, value]) => (

                      <Panel title={key} key={key}>

                        <ProductRows value={value} />

                      </Panel>


                    )
                    )
                  }


                </div>

                <GetCart />


              </div>

            </div >
          )}
          {!data.loading && data.services.length === 0 && !data.error && <p>No services found.</p>}





        </div>
      </>
    );

  };

  const Step2 = () => {





    const { handleStep, previousStep, nextStep, goToStep } = useWizard();
    function GetProviders() {
      console.log(dataToBeUploaded.total)
      // Definer en funktion
      function capitalize(str) {
        // Gør det første tegn til stort bogstav
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
      const firestore = useFirestore();

      const animalsCollection = collection(
        firestore,
        '/providers/' + country + '/location/'
      );
      const animalsQuery = query(animalsCollection, where('active', "==", true));

      const { status, data: locations } = useFirestoreCollectionData(
        animalsQuery,
        {
          idField: 'id', // this field will be added to the object created from each document
        }
      );
      if (status === 'loading') {
        return <p>Fetching burrito flavor...</p>;
      }

      return (
        <div>
          <div className='provider-grid'>
            {locations.map((locations) => (
              <div className='card-provider' key={locations.id}>
                <div
                  className='provider-link'
                  onClick={() => setProvider(locations.name, locations.id)}
                  key={locations.id}
                >
                  <div className='flex-provider'>
                    <img className='provider-image' src={locations.img}></img>
                    <span className='title' style={{ marginBottom: 5 }}>{capitalize(locations.name)}</span>
                    <p>{locations.address} </p>

                    <div>
                      {locations?.notes &&
                        <div style={{ backgroundColor: 'rgba(124,172,248,0.6)', padding: 15, borderRadius: 10, margin: 10 }}>
                          {locations?.notes}

                        </div>
                      }

                    </div>
                  </div>

                </div>
              </div>
            ))}

          </div>
          <div className='stepsSelector'>
            <a className='confirm' onClick={() => previousStep()}>{t("Previous")}</a>
          </div>
        
        </div>

      );
    }
    // Attach an optional handler
    handleStep(() => { });

    function setProvider(x, y) {
      dataToBeUploaded.provider = x;
      dataToBeUploaded.providerName = y;

      dataToBeUploaded.providerId = y;

      nextStep();
    }

    return (
      <>
        <div className="steps">
          <a data-step-name={step1Name} onClick={() => goToStep(0)} data-active="d"></a>
          <a data-step-name={step2Name} data-active="y"></a>
          <a data-step-name={step3Name} data-active=""></a>
          <a data-step-name={step4Name} data-active=""></a>
          <a data-step-name={step5Name} data-active=""></a>
          <a data-step-name={step6Name} data-active=""></a>

        </div>
        <h2 className='title'>{t('Provider')}</h2>
        <p>{t("")}</p>
        <GetProviders />


      </>
    );
  };

  const Step3 = () => {
    const { handleStep, previousStep, nextStep, goToStep } = useWizard();
  
  // Determine lead time dynamically based on country
  const getInitialDate = () => {
    if (country === 'danmark') {
      return moment().add(5, 'days').toDate();
    } else if (country === 'south africa') {
      return moment().add(1, 'days').toDate();
    } else {
      return moment().add(0, 'days').toDate();
    }
  };

  const [value, onChange] = useState(getInitialDate());
  const day = moment(value).format('dddd').toLowerCase();
  const firestore = useFirestore();

  const timeVal = doc(
    firestore,
    '/providers/' + country + '/location/' + dataToBeUploaded.providerId + '/workTime/',
    day
  );

  const { status, data } = useFirestoreDocData(timeVal, {
  });

  useEffect(() => {
    dataToBeUploaded.dateSlot = moment(value).format('YYYY-MM-DD');
  }, [value]);

  const setDate = (date) => {
    onChange(date);
    dataToBeUploaded.dateSlot = moment(date).format('YYYY-MM-DD');
  };

  const setTime = (time) => {
    dataToBeUploaded.timeSlot = time;
    nextStep();
  };

  const Filtered = () => {
    if (status === 'loading') {
      return <p>Fetching...</p>;
    }

    if (!data) {
      return <p>No available time slots</p>;
    }

    let leadTimeDate = moment();
    if (country === 'danmark') {
      leadTimeDate = moment().add(5, 'days');
    } else if (country === 'south africa') {
      leadTimeDate = moment().add(1, 'days');
    } else {
      leadTimeDate = moment().add(0, 'days');
    }

    const isCurrentDay = moment(value).isSame(moment(), 'day');

    if (moment(value).isBefore(leadTimeDate, 'day')) {
      return <p>No available time slots</p>;
    }

    const filteredData = Object.entries(data).filter(([key, val]) => {
      if (!val) return false;
      if (isCurrentDay) {
        return moment(key, "HH:mm").isAfter(moment());
      }
      return true;
    });

    if (filteredData.length === 0) {
      return <p>No available time slots</p>;
    }

    return filteredData
      .sort()
      .map(([key]) => (
        <a className='time' key={key} onClick={() => setTime(key)}>
          {key}
        </a>
      ));
  };

  return (
    <>
      <div className="steps">
        <a data-step-name={step1Name} onClick={() => goToStep(0)} data-active="d"></a>
        <a data-step-name={step2Name} onClick={() => goToStep(1)} data-active="d"></a>
        <a data-step-name={step3Name} data-active="y"></a>
        <a data-step-name={step4Name} data-active=""></a>
        <a data-step-name={step5Name} data-active=""></a>
        <a data-step-name={step6Name} data-active=""></a>
      </div>
      <h2 className='title'>{t('Select date & time')}</h2>
      <div className='calendar-flex'>
        <Calendar
          onChange={(date) => setDate(date)}
          value={value}
        />
      </div>
      <h2 className='time-title'>{t('Select your preferred drop-off')}</h2>
      <div className='time-flex'>
        <Filtered />
      </div>
      <div className='stepsSelector'>
        <a className='confirm' onClick={() => previousStep()}>{t("Previous")}</a>
      </div>
    </>
  );
};

  const Step4 = () => {
    const { handleStep, previousStep, nextStep, goToStep } = useWizard();
    // State to store uploaded file

    const [file, setFile] = useState(''); // progress
    const [isUploaded1, setIsUploaded1] = useState(null);
    const [preview1, setPreview1] = useState(null);

    const [isUploaded2, setIsUploaded2] = useState(null);
    const [preview2, setPreview2] = useState(null);

    const [isUploaded3, setIsUploaded3] = useState(null);
    const [preview3, setPreview3] = useState(null);

    const [isUploaded4, setIsUploaded4] = useState(null);
    const [preview4, setPreview4] = useState(null);

    const [uploadedCount, setUploadedCount] = useState([
      dataToBeUploaded.image1,
      dataToBeUploaded.image2,
      dataToBeUploaded.image3,
      dataToBeUploaded.image4
    ]
    );


    const [percent, setPercent] = useState(0); // Handle file upload event and update state

    const handleChange1 = async (event) => {
      setPreview1(URL.createObjectURL(event.target.files[0]))
      upload(event.target.files[0], 1);
      setIsUploaded1(true);
    };
    const handleChange2 = async (event) => {
      setPreview2(URL.createObjectURL(event.target.files[0]))

      upload(event.target.files[0], 2);
      setIsUploaded2(true);

    };
    const handleChange3 = async (event) => {
      setPreview3(URL.createObjectURL(event.target.files[0]))

      upload(event.target.files[0], 3);
      setIsUploaded3(true);

    };
    const handleChange4 = async (event) => {
      setPreview4(URL.createObjectURL(event.target.files[0]))
      upload(event.target.files[0], 4);
      setIsUploaded4(true);

    };

    function uuidv4() {
      return ([1e7] + 1e3 + 4e3 + 8e3 + 1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    }

    function countUpload() {
      if (dataToBeUploaded.image1 != null && dataToBeUploaded.image2 != null && dataToBeUploaded.image3 != null && dataToBeUploaded.image4 != null) {
        nextStep()
      }
    }

    async function upload(x, y) {
      if (!x) {
        alert('Please upload an image first!');
      }
      const storageRef = ref(storage, `/files/${uuidv4() + x.name}`); // progress can be paused and resumed. It also exposes progress updates. // Receives the storage reference and the file to upload.
      const uploadTask = uploadBytesResumable(storageRef, x);
      await uploadTask.on(
        'state_changed',
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          ); // update progress
          setPercent(percent);
        },
        (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            var image = y;
            if (y === 1) {
              dataToBeUploaded.image1 = url;
            }
            if (y == 2) {
              dataToBeUploaded.image2 = url;
            }
            if (y == 3) {
              dataToBeUploaded.image3 = url;
            }
            if (y == 4) {
              dataToBeUploaded.image4 = url;
            }
            countUpload()
          });
        }
      );
    }

    handleStep(() => { });

    return (
      <>
        <div className="steps">
          <a data-step-name={step1Name} onClick={() => goToStep(0)} data-active="d"></a>
          <a data-step-name={step2Name} onClick={() => goToStep(1)} data-active="d"></a>
          <a data-step-name={step3Name} onClick={() => goToStep(2)} data-active="d"></a>
          <a data-step-name={step4Name} data-active="y"></a>
          <a data-step-name={step5Name} data-active=""></a>
          <a data-step-name={step6Name} data-active=""></a>

        </div>
        <h2 className='title'>{t('Upload Images')}</h2>
        <p>{t("upload description")}</p>


        <div className='file-flex'>
          <div className='file-inner'>
            <img className='imgPreview' src={preview1}></img>
            <label htmlFor="img1" className={isUploaded1 ? "file-button-after-upload" : "file-button-before-upload"}>

              <i className="fa fa-cloud-upload"></i> {t('Choose image 1')}
            </label>
            <input type="file" id="img1" onChange={handleChange1} accept="/image/*" />
          </div>

          <div className='file-inner'>
            <img className='imgPreview' src={preview2}></img>

            <label htmlFor="img2" className={isUploaded2 ? "file-button-after-upload" : "file-button-before-upload"}>
              <i className="fa fa-cloud-upload"></i> {t('Choose image 2')}
            </label>
            <input type="file" id="img2" onChange={handleChange2} accept="/image/*" />

          </div>
          <div className='file-inner'>
            <img className='imgPreview' src={preview3}></img>

            <label htmlFor="img3" className={isUploaded3 ? "file-button-after-upload" : "file-button-before-upload"}>
              <i className="fa fa-cloud-upload"></i> {t('Choose image 3')}
            </label>
            <input type="file" id="img3" onChange={handleChange3} accept="/image/*" />

          </div>
          <div className='file-inner'>
            <img className='imgPreview' src={preview4}></img>
            <label htmlFor="img4" className={isUploaded4 ? "file-button-after-upload" : "file-button-before-upload"}>
              <i className="fa fa-cloud-upload"></i> {t('Choose image 4')}
            </label>
            <input type="file" id="img4" onChange={handleChange4} accept="/image/*" />

          </div>
        </div>
        <div className='space'></div>

        <div className='stepsSelector'>
          <a className='confirm' onClick={() => previousStep()}>{t("Previous")}</a>
          <a className='confirm' onClick={() => nextStep()}>{t("Next")}</a>
        </div>

      </>
    );
  };

  const Step5 = () => {
    const { handleStep, previousStep, nextStep, goToStep } = useWizard();
    const [firstName, setFirstName] = useState(dataToBeUploaded.clientNameFirst);
    const [lastName, setLastName] = useState(dataToBeUploaded.clientNameLast);
    const [email, setEmail] = useState(dataToBeUploaded.clientEmail);
    const [phone, setPhone] = useState(dataToBeUploaded.phone);
    const [carmanu, setCarmanu] = useState(dataToBeUploaded.carmanu);
    const [regnumber, setRegnumber] = useState(dataToBeUploaded.regnumber);
    const [isCompany, setIsCompany] = useState(false);
    const [company, setCompany] = useState(dataToBeUploaded.company);
    const [organizationsNumber, setOrganizationsNumber] = useState(dataToBeUploaded.organizationsNumber);



    handleStep(() => { });
    const handleCheckboxChange = () => {
      setIsCompany(!isCompany);
    };
    const handleChange = (event) => {
      if (event.target.name === 'firstName') {
        setFirstName(event.target.value);
        dataToBeUploaded.clientNameFirst = event.target.value;
      }
      if (event.target.name === 'lastName') {
        setLastName(event.target.value);
        dataToBeUploaded.clientNameLast = event.target.value;
      }
      if (event.target.name === 'email') {
        setEmail(event.target.value);
        dataToBeUploaded.clientEmail = event.target.value;
      }
      if (event.target.name === 'carmanu') {
        setCarmanu(event.target.value);
        dataToBeUploaded.carmanu = event.target.value;
      }
      if (event.target.name === 'phone') {
        setPhone(event.target.value);
        dataToBeUploaded.phone = event.target.value;
      }
      if (event.target.name === 'regnumber') {
        setRegnumber(event.target.value);
        dataToBeUploaded.regnumber = event.target.value;
      }
      if (event.target.name === 'company') {
        setCompany(event.target.value);
        dataToBeUploaded.company = event.target.value;
      }
      if (event.target.name === 'organizationsNumber') {
        setOrganizationsNumber(event.target.value);
        dataToBeUploaded.regnumber = event.target.organizationsNumber;
      }
    };

    const handleNextStep = () => {
      if (validateForm()) {
        nextStep();
      } else {
        alert("Please fill out all required fields. ");
      }
    };



    const [isFormValid, setIsFormValid] = useState(false);

    // New function to validate the form
    const validateForm = () => {
      // Check if all required fields are filled
      return firstName.trim() !== '' &&
        lastName.trim() !== '' &&
        email.trim() !== '' &&
        phone.trim() !== '' &&
        carmanu.trim() !== '' &&
        regnumber.trim() !== '';
    };


    // Function to update form validity
    const updateFormValidity = () => {
      setIsFormValid(
        firstName.trim() !== '' &&
        lastName.trim() !== '' &&
        email.trim() !== '' &&
        phone.trim() !== '' &&
        carmanu.trim() !== '' &&
        regnumber.trim() !== ''
      );
    };

    useEffect(() => {
      updateFormValidity();
    }, [firstName, lastName, email, phone, carmanu, regnumber]);

    return (
      <>
        <div className="steps">
          <a data-step-name={step1Name} onClick={() => goToStep(0)} data-active="d"></a>
          <a data-step-name={step2Name} onClick={() => goToStep(1)} data-active="d"></a>
          <a data-step-name={step3Name} onClick={() => goToStep(2)} data-active="d"></a>
          <a data-step-name={step4Name} onClick={() => goToStep(3)} data-active="d"></a>
          <a data-step-name={step5Name} data-active="y"></a>
          <a data-step-name={step6Name} data-active=""></a>

        </div>
        <h2 className='title'>{t("Personal Details")}</h2>
        <p>{t("In order to serve you better, we need some information about you. Please fill in your personal details in this form.")}</p>
        <div className='rowInput'>
          <input
            type="text"

            autoComplete="given-name"
            value={firstName}
            name="firstName"
            placeholder={t("First name")}
            onChange={handleChange}
          />
          <input
            type="text"
            autoComplete="family-name"
            value={lastName}
            placeholder={t("Last name")}
            name="lastName"
            onChange={handleChange}
          />
        </div>
        <input
          type="text"

          autoComplete="email"
          value={email}
          placeholder={t("Your email")}
          name="email"
          onChange={handleChange}
        />
        <input
          type="text"

          autoComplete="phone"
          value={phone}
          placeholder={t("Phone")}
          name="phone"
          onChange={handleChange}
        />
        <div className='space'></div>

        <label for="switch" className='switchLabel'>{t("Company?")}
          <label className="switch">
            <input type="checkbox"
              checked={isCompany}
              onChange={handleCheckboxChange}
            />
            <span className="slider round"></span>
          </label></label>

        {/* Conditional rendering of div based on checkbox status  Organizations number */}
        {isCompany && <div className='rowInput'>
          <input
            type="text"

            autoComplete="organization"
            value={company}
            name="company"
            placeholder={t("Company name")}
            onChange={handleChange}
          />
          <input
            type="text"
            autoComplete="off"
            value={organizationsNumber}
            placeholder={t("Organizations number")}
            name="organizationsNumber"
            onChange={handleChange}
          />
        </div>


        }
        <div className='space'></div>

        <h2 className='title'>{t("Car details")}</h2>
        <p>{t("To provide you with a more accurate and efficient service, we require some information about your vehicle. Please enter your car details in the form below.")}</p>
        <input
          type="text"
          value={carmanu}
          name="carmanu"
          placeholder={t("Car manufactor")}
          onChange={handleChange}
        />
        <input
          type="text"
          value={regnumber}
          placeholder={t("Registration Number")}
          name="regnumber"
          onChange={handleChange}
        />

        <div className='space'></div>
        <div className='stepsSelector'>
          <a className='confirm' onClick={() => previousStep()}>{t("Previous")}</a>
          <a className={`confirm ${isFormValid ? '' : 'disabled'}`} onClick={isFormValid ? handleNextStep : null}>{t("Next")}</a>        </div>
      </>
    );
  };
  const Step6 = () => {
    const { handleStep, previousStep, nextStep, goToStep } = useWizard();
    const [message, setMessage] = useState('');

    handleStep(() => { });

    function checkFields(data) {
      let message = '';
      let isValid = true;

      // Check if car manufacturer is not empty or undefined
      if (!data.carmanu) {
        message += 'Car manufacturer is required. ';
        isValid = false;
      }

      // Check if client email is valid
      if (!data.clientEmail || !data.clientEmail.includes('@')) {
        message += 'Invalid client email. ';
        isValid = false;
      }

      // Check if client ID, first name, and last name are not empty
      if (!data.clientNameFirst.trim() || !data.clientNameLast.trim()) {
        message += 'Client information is incomplete. ';
        isValid = false;
      }


      // Check if dateSlot is not empty and is a valid date
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      if (!data.dateSlot || !dateRegex.test(data.dateSlot)) {
        message += 'Invalid or missing date slot. ';
        isValid = false;
      }





      // Check if phone number is not empty and valid
      if (!data.phone) {
        message += 'Phone number is required. ';
        isValid = false;
      }

      // Check if provider, provider country, provider ID, and provider name are not empty
      if (!data.provider || !data.providerCountry || !data.providerId || !data.providerName) {
        message += 'Provider information is incomplete. ';
        isValid = false;
      }

      // Check if regnumber is not empty (if it's a required field)
      if (data.regnumber === '') {
        message += 'Registration number is required. ';
        isValid = false;
      }

      // Check if service array is empty
      if (!data.service || !data.service.length) {
        message += 'At least one service must be selected. ';
        isValid = false;
      }


      // Check if timeSlot is not empty
      if (!data.timeSlot) {
        message += 'Time slot is required. ';
        isValid = false;
      }



      if (isValid) {
        setMessage(message.trim())
        console.log(isValid);
        nextStep();
      } else {
        setMessage(message.trim())

        console.log(data);
        console.log(isValid);

      }
    }






    return (
      <>
        <div className="steps">
          <a data-step-name={step1Name} onClick={() => goToStep(0)} data-active="d"></a>
          <a data-step-name={step2Name} onClick={() => goToStep(1)} data-active="d"></a>
          <a data-step-name={step3Name} onClick={() => goToStep(2)} data-active="d"></a>
          <a data-step-name={step4Name} onClick={() => goToStep(3)} data-active="d"></a>
          <a data-step-name={step5Name} onClick={() => goToStep(4)} data-active="d"></a>
          <a data-step-name={step6Name} data-active="y"></a>
        </div>

        <h2>{t("Confirmation")}</h2>
        <p>{t("in order to ensure accuracy, we kindly request that you take a moment to review the information you entered and confirm that it is correct. This will help us to ensure that we have the correct details on file and that we can provide you with the best possible service.")}</p>
        
        <table className='table-personal'>
          <tr>
            <td>{t("Name")}</td>
            <td>{dataToBeUploaded.clientNameFirst} {dataToBeUploaded.clientNameLast}</td>
          </tr>
          <tr>
            <td>{t("Email")}</td>
            <td>{dataToBeUploaded.clientEmail}</td>
          </tr>
          <tr>
            <td>{t("Phone Number")}</td>
            <td>{dataToBeUploaded.phone}</td>
          </tr>
          <tr>
            <td>{t("Time slot")}</td>
            <td>{dataToBeUploaded.timeSlot} {dataToBeUploaded.dateSlot}</td>
          </tr>
          <tr>
            <td>{t("Location")}</td>
            <td>{dataToBeUploaded.provider}</td>
          </tr>
          <tr>
            <td>{t("Car Registration number")}</td>
            <td>{dataToBeUploaded.regnumber}</td>
          </tr>

          <tr>
            <td>{t("discount")}:</td>
            <td>{dataToBeUploaded.discount}</td>
          </tr>
        </table>
        <p>{message}</p>
        <div className='space'></div>

        <div className='stepsSelector'>
          <a className='confirm' onClick={() => previousStep()}>{t("Previous")}</a>
          <a className='confirm' onClick={() => checkFields(dataToBeUploaded)}>{t("Confirm")}</a>
        </div>

      </>
    );
  };

  const Step7 = () => {
    const { handleStep, previousStep, nextStep, goToStep } = useWizard();


    handleStep(() => { });

    try {
      addData(dataToBeUploaded);
      TagManager.dataLayer({ dataLayer: { event: 'purchase', dataToBeUploaded } });
      return (
        <>
          <div className='thxblock'>
            <i className="fa fa-check thxicon"></i>
            <h2 className='thxtitle'>{t("Thank you for your booking!")}</h2>
            <p className='thxtext'>{t("You should receive a confirmation email shortly. Feel free to close the window.")}</p>
          </div>
        </>
      );
    } catch (error) {
      console.error("Error occurred while adding data:", error);
      // Handle the error here, such as displaying an error message to the user
      return (
        <>
          <div className='errorblock'>
            <i className="fa fa-exclamation-triangle erroricon"></i>
            <h2 className='errortitle'>{t("Error occurred while processing your booking!")}</h2>
            <p className='errortext'>{t("Please try again later or contact customer support for assistance.")}</p>
          </div>
        </>
      );
    }
  };

  const Link = () => {
    if (country == "usa") {
      return <a href='https://repair2care.com/' className='text-right'>{t('Return to frontpage')}</a>
    }
    if (country == "germany") {
      return <a href='https://repair2care.com/de_de/' className='text-right'>{t('Return to frontpage')}</a>
    }
    if (country == "south africa") {
      return <a href='https://repair2care.com/en_za/' className='text-right'>{t('Return to frontpage')}</a>
    }
    if (country == "danmark") {
      return <a href='https://repair2care.com/da/' className='text-right'>{t('Return to frontpage')}</a>
    } else {
      return <a href='https://repair2care.com/' className='text-right'>{t('Return to frontpage')}</a>
    }
  }

  const Total = () => {

    return (
      <div className='payments-container'>
        <div className='payments'>
          <h4 className='payments-text'>Total: {dataToBeUploaded.total}</h4>

        </div>
      </div>

    );
  }

  return (
    <FirestoreProvider sdk={firestoreInstance}>
   
      <div className='header'>
        <div className='header-body'>
          <div className='item'>
            <img className='header-img' src="https://repair2care.com/wp-content/uploads/2023/01/R2C-negativ.png"></img>

          </div>
          <div className='item'>
            <Link />

          </div>
        </div>
      </div>

      <div className='body-container'>
        <Wizard>
          <Step1 />
          <Step2 />
          <Step3 />
          <Step4 />
          <Step5 />
          <Step6 />
          <Step7 />

        </Wizard>

        <ToastContainer
          position="bottom-right"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </div>

    </FirestoreProvider>
  );
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>

    <App />
  </FirebaseAppProvider>
);
